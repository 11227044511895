
import { defineComponent, computed, PropType } from 'vue'

import Time from '@web/common/Time'
import i18n from '@web/plugins/i18n'
import { getNumberDecliner } from '@web/common/Utils'

enum Type {
  ADD = 'add',
  DEBIT = 'debit'
}

export default defineComponent({
  name: 'HistoryCard',
  props: {
    date: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    type: {
      type: String as PropType<Type.ADD | Type.DEBIT | null>,
      default: null
    },
    points: {
      type: Number,
      default: null
    }
  },
  setup (props) {
    const prettyDate = computed(() => props.date ? Time(props.date).format('DD.MM.YYYY') : '')
    const prettyTime = computed(() => props.date ? Time(props.date).format('H:mm') : '')

    const icon = computed(() => {
      if (props.type === Type.DEBIT) {
        return 'icon'
      }

      return 'icon'
    })

    const pointsText = computed(() => {
      const text = getNumberDecliner({
        n: props.points,
        single: i18n.global.t('pointsDecliner.point'),
        multiple: i18n.global.t('pointsDecliner.points'),
        multiple2: i18n.global.t('pointsDecliner.points2')
      })

      if (!props.type) {
        return `${props.points} ${text}`
      }

      return props.type === Type.DEBIT ? `- ${props.points} ${text}` : `+ ${props.points} ${text}`
    })

    const typeText = computed(() => {
      if (!props.type) {
        return ''
      }

      return props.type === Type.DEBIT ? i18n.global.t('pointsPage.debitPoints') : i18n.global.t('pointsPage.addPoints')
    })

    return {
      prettyDate,
      prettyTime,
      icon,
      pointsText,
      typeText
    }
  }
})
