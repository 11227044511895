import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6abb3795"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "points-history" }
const _hoisted_2 = { class: "points-history_title" }
const _hoisted_3 = { class: "points-history_content" }
const _hoisted_4 = { class: "points-history_actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_history_card = _resolveComponent("history-card")!
  const _component_ac_button = _resolveComponent("ac-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('History')), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userScore, (value, key) => {
        return (_openBlock(), _createBlock(_component_history_card, {
          key: key,
          date: value.updatedAt,
          title: value.title,
          text: value.text,
          type: value.type,
          points: value.amount
        }, null, 8, ["date", "title", "text", "type", "points"]))
      }), 128))
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ac_button, {
        onClick: _ctx.nextPage,
        variant: "tonal"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('NextPage')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ], 512), [
      [_vShow, _ctx.userPaginationParam.currentPage < _ctx.userPaginationParam.pageCount]
    ])
  ]))
}