import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1916eef8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "history-card_wrapper" }
const _hoisted_2 = { class: "history-card_row -space-between" }
const _hoisted_3 = { class: "history-card_column" }
const _hoisted_4 = { class: "history-card_row" }
const _hoisted_5 = { class: "history-card_column" }
const _hoisted_6 = { class: "history-card_column" }
const _hoisted_7 = { class: "history-card_date date" }
const _hoisted_8 = { class: "date_date" }
const _hoisted_9 = { class: "date_time" }
const _hoisted_10 = { class: "history-card_column -flex-end" }
const _hoisted_11 = { class: "history-card_type" }
const _hoisted_12 = { class: "history-card_row" }
const _hoisted_13 = { class: "history-card_column" }
const _hoisted_14 = { class: "history-card_row" }
const _hoisted_15 = { class: "history-card_title" }
const _hoisted_16 = {
  key: 0,
  class: "history-card_row"
}
const _hoisted_17 = { class: "history-card_text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['history-card', `-${_ctx.type}`])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: _normalizeClass(['history-card_icon', `-${_ctx.type}`])
              }, null, 2)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "date", {}, () => [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _renderSlot(_ctx.$slots, "date_date", {}, () => [
                      _createTextVNode(_toDisplayString(_ctx.prettyDate), 1)
                    ], true)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _renderSlot(_ctx.$slots, "date_time", {}, () => [
                      _createTextVNode(_toDisplayString(_ctx.prettyTime), 1)
                    ], true)
                  ])
                ])
              ], true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", {
            class: _normalizeClass(['history-card_points', `-${_ctx.type}`])
          }, [
            _renderSlot(_ctx.$slots, "points", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.pointsText), 1)
            ], true)
          ], 2),
          _createElementVNode("div", _hoisted_11, [
            _renderSlot(_ctx.$slots, "type", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.typeText), 1)
            ], true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _renderSlot(_ctx.$slots, "title", {}, () => [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
              ], true)
            ])
          ]),
          (_ctx.text)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _renderSlot(_ctx.$slots, "text", {}, () => [
                    _createTextVNode(_toDisplayString(_ctx.text), 1)
                  ], true)
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 2))
}