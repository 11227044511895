import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "asBreadcrumb"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.showBreadcrumbs)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbsComputed, (crumb, i) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: i,
            to: crumb,
            class: "asBreadcrumb_item",
            "data-analytics": "",
            "data-analytics-element-type": "menu_breadcrumbs",
            "data-analytics-element-target": crumb,
            "data-analytics-element-id": crumb.name,
            "data-analytics-element-name": crumb.title
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(crumb.title), 1)
            ]),
            _: 2
          }, 1032, ["to", "data-analytics-element-target", "data-analytics-element-id", "data-analytics-element-name"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}