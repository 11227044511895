
import { computed, defineComponent, onMounted } from 'vue'

import HistoryCard from '@web/components.v2/Points/HistoryCard.vue'

import { useStore } from '@web/store'
import Logger from '@web/common/Logger'
import sentry from '@web/common/sentry'
import AcButton from '@ui-kit/components/AcButton.vue'

export default defineComponent({
  name: 'AcPointsHistory',
  components: {
    AcButton,
    HistoryCard
  },
  setup () {
    const store = useStore()

    const userScore = computed(() => store.getters['userScore/history'])
    const userPaginationParam = computed(() => store.getters['userScore/paginationParam'])

    async function init () {
      try {
        await store.dispatch('userScore/history', { page: userPaginationParam.value.currentPage })
      } catch (error) {
        sentry.captureException(error)
        Logger.error(error)
      }
    }

    async function nextPage () {
      try {
        await store.dispatch('userScore/history', { page: userPaginationParam.value.currentPage + 1 })
      } catch (error) {
        sentry.captureException(error)
        Logger.error(error)
      }
    }

    onMounted(() => {
      init()
    })

    return {
      userScore,
      userPaginationParam,
      history,
      nextPage
    }
  }
})
