
import { defineComponent, computed } from 'vue'
import i18n from '@web/plugins/i18n'
import { useRoute } from 'vue-router'
import PAGE_NAME from '@web/consts/PAGE_NAME'

export type crumb = {
  name: string;
  title: string;
  params?: Dictionary<string | string[]>;
}

export default defineComponent({
  props: {
    title: {
      type: String,
      default: () => ''
    },
    breadcrumbs: {
      type: Array,
      default: () => [] as crumb[]
    }
  },
  setup (props) {
    const route = useRoute()
    const breadcrumbsComputed = computed<crumb[]>(() => {
      // TODO: всегда передавать снаружи хлебные крошки
      if (Array.isArray(props.breadcrumbs) && props.breadcrumbs.length > 0) {
        return props.breadcrumbs
      }

      let crumbs: crumb[] = [
        {
          name: PAGE_NAME.HOME,
          title: i18n.global.t('crumb.general')
        }
      ]

      if (
        route.meta &&
        Array.isArray(route.meta.crumbs)
      ) {
        const routeCrumbs = route.meta.crumbs.map(crumb => {
          if (crumb.name === 'calendar' && route.name === 'day') {
            crumb.params = {
              courseId: route.params.courseId,
              streamId: route.params.streamId
            }
          }
          return crumb
        })
        crumbs = crumbs.concat(routeCrumbs)
      }

      if (route.name !== 'courses' && route.name !== PAGE_NAME.HOME) {
        crumbs.push({
          name: String(route.name) || '',
          title: props.title
        })
      }

      return crumbs
    })

    const showBreadcrumbs = computed(() => breadcrumbsComputed.value.length > 1)

    return {
      breadcrumbsComputed,
      showBreadcrumbs
    }
  }
})
