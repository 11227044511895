
import { defineComponent, computed } from 'vue'
import { useStore } from '@web/store'

export default defineComponent({
  setup () {
    const store = useStore()

    // const faq = computed(() => store.getters['assets/faq'])
    // const contact = computed(() => store.getters['assets/contact'])
    const footer = computed(() => store.getters['assets/footer'])

    return {
      // faq,
      // contact,
      footer
    }
  }
})
